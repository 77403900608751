import React, { memo, useEffect } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ProductListComponent from 'components/ProductList';
import { searchSelectors } from 'states/Search';
import { productSelectors } from 'states/Products';
import { productActions } from '../../states/Products';
import { useProductList } from 'hooks/useProductList';
import { useCategories } from 'hooks/useCategories';
import { appSelectors } from 'providers/AppProvider/data';
import messages from 'messages/Product';

const ProductList = ({ intl }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 720px)');
  const { getCategories, requestCategories } = useCategories();
  const { fetchProductList, params, setParams, isLoading } = useProductList();

  const categoriesObj = useSelector(appSelectors.categoriesObj);
  const productList = useSelector(productSelectors.productListShort);
  const hasMore = useSelector(productSelectors.hasMore);
  const currency = useSelector(searchSelectors.currency);
  const selectedCategory = useSelector(searchSelectors.selectedCategory);

  const handleSearchSubmit = (searchQuery) => {
    setParams((prevParams) => ({
      ...prevParams,
      search: searchQuery,
    }));
  };

  useEffect(() => {
    if (JSON.stringify(categoriesObj).length > 3 || requestCategories) return;
    getCategories();
    return () => {};
  }, []);

  useEffect(() => {
    fetchProductList(0, false);
    dispatch(productActions.setPrevParams({ ...params }));
    return () => {};
  }, [params]);

  if (isMobile) {
    document.getElementById('scroller').style.background = '#fff';
  }

  return (
    <ProductListComponent
      products={productList || []}
      fetchMore={fetchProductList}
      hasMore={hasMore}
      currency={currency}
      intl={intl}
      messages={messages}
      title={
        categoriesObj && categoriesObj[selectedCategory]
          ? categoriesObj[selectedCategory][`title_${intl.locale}`]
          : intl.formatMessage({ ...messages.newProducts })
      }
      onSearchSubmit={handleSearchSubmit}
      isLoading={isLoading}
    />
  );
};

ProductList.propTypes = {
  intl: intlShape,
};

export default compose(memo, injectIntl)(ProductList);
