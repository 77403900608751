import React, { useState, useEffect } from 'react';
import {
  getFavoriteIds,
  deleteFavoriteProduct,
  setFavoriteProduct,
} from '../api/favoriteApi';

export const useFavoriteProducts = () => {
  const [favoriteProducts, setFavoriteProducts] = useState(new Map());

  const fetchFavoriteProducts = async () => {
    try {
      const favorites = await getFavoriteIds();
      setFavoriteProducts(new Map(favorites.map((id) => [id, true])));
    } catch (error) {
      console.error(
        'Failed to fetch favorite products:',
        error.message || error,
      );
    }
  };

  const toggleFavorite = async (id) => {
    try {
      if (favoriteProducts.has(id)) {
        await deleteFavoriteProduct(id);
        setFavoriteProducts((prev) => {
          const updated = new Map(prev);
          updated.delete(id);
          return updated;
        });
      } else {
        await setFavoriteProduct(id);
        setFavoriteProducts((prev) => new Map(prev).set(id, true));
      }
    } catch (error) {
      console.error(
        `Failed to update favorite status for product ${id}:`,
        error.message || error,
      );
    }
  };

  useEffect(() => {
    fetchFavoriteProducts();
  }, []);

  return { favoriteProducts, toggleFavorite };
};
