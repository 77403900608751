import React from 'react';
import { useStyles } from '../styles';
import ProductStateActive from './ProductStateActive';
import ProductStateNotActive from './ProductStateNotActive';

import { Box, Typography, IconButton } from '@material-ui/core';
import PriceAndTitle from '../components/PriceAndTitle';
import { FavoriteOutlined, FavoriteBorder } from '@material-ui/icons';

const ProductState = ({
  product,
  disableLink,
  onReview,
  isSelect,
  isMyProfile,
  intl,
  messages,
  user,
  discountPrice,
  openAuthForm,
  handleFavorite,
  isFavorite,
}) => {
  const classes = useStyles();

  const handleClick = (e) => {
    if (user?.info) {
      e.preventDefault();
      handleFavorite(product.id);
    } else {
      openAuthForm();
    }
  };

  const getComponent = (type, payload) => {
    switch (type) {
      case 'getDiscount': {
        if (payload) {
          return (
            <Box className={classes.discount}>
              <Typography component={'p'}>-{discountPrice}%</Typography>
            </Box>
          );
        }
        break;
      }
      case 'getIsNotSelectProfile': {
        if (payload && user?.info) {
          return (
            <IconButton
              className={classes.favoriteButton}
              onClick={handleClick}
            >
              {isFavorite ? (
                <FavoriteOutlined color="primary" />
              ) : (
                <FavoriteBorder color="primary" />
              )}
            </IconButton>
          );
        }
        break;
      }
      case 'getPriceAndTitle': {
        return (
          <PriceAndTitle
            intl={intl}
            product={product}
            messages={messages}
            isDiscount={isDiscount}
            onReview={onReview}
            handleFavorite={handleFavorite}
            isFavorite={isFavorite}
          />
        );
      }
      default:
        break;
    }
  };

  const isDiscount =
    product?.user.is_business &&
    product?.user.request_status !== 'expired' &&
    product?.[`discount_price_${product?.currency.toLowerCase()}`];

  return (
    <>
      {product?.state === 'active' ? (
        <ProductStateActive
          getComponent={getComponent}
          isDiscount={isDiscount}
          product={product}
          disableLink={disableLink}
          onReview={onReview}
          isSelect={isSelect}
          isMyProfile={isMyProfile}
        />
      ) : (
        <ProductStateNotActive
          getComponent={getComponent}
          isDiscount={isDiscount}
          onReview={onReview}
          product={product}
          isSelect={isSelect}
          isMyProfile={isMyProfile}
        />
      )}
    </>
  );
};

export default ProductState;
