import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import BusinessProduct from 'components/BusinessProduct';
import { useFavoriteProducts } from 'fsd/entities/Favorite/hooks/useFavoriteProducts';

const Index = ({
  intl,
  products,
  setProducts,
  saveChanges,
  isSelect,
  selected,
  isMyProfile,
  withoutSwitch,
  className,
  newProductsTab,
  onUser,
}) => {
  const classes = useStyles();
  const { favoriteProducts, toggleFavorite } = useFavoriteProducts();

  return (
    <div className={`${classes.root} ${className}`}>
      {products &&
        products.map((product, idx) => (
          <div
            key={`${product.id}-${idx}`}
            className={classes.rootProduct}
          >
            <BusinessProduct
              intl={intl}
              product={product}
              setProducts={setProducts}
              userId={product?.user}
              saveChanges={saveChanges}
              isSelect={isSelect}
              selected={selected}
              isMyProfile={isMyProfile}
              onUser={onUser}
              withoutSwitch={withoutSwitch}
              newProductsTab={newProductsTab}
              handleFavorite={toggleFavorite}
              isFavorite={favoriteProducts.has(product.id)}
            />
          </div>
        ))}
    </div>
  );
};

Index.propTypes = {
  intl: PropTypes.object,
  products: PropTypes.array.isRequired,
  setProducts: PropTypes.func,
  saveChanges: PropTypes.func,
  isSelect: PropTypes.bool,
  selected: PropTypes.func,
  isMyProfile: PropTypes.bool,
  withoutSwitch: PropTypes.bool,
  fetchMore: PropTypes.func,
  isFavoritePage: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  newProductsTab: PropTypes.bool,
  checkProducts: PropTypes.object,
};

export default Index;
