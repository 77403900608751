import { appActions, appSelectors } from 'providers/AppProvider/data';
import { useDispatch, useSelector } from 'react-redux';

let requestCategories = false;
export const useCategories = () => {
  const dispatch = useDispatch();
  const categories = useSelector(appSelectors.categoryAndChildren);

  const getAllCategories = async () => {
    if (categories.length) return;
    try {
      await dispatch(appActions.getAllCategories());
    } catch (e) {
      console.warn(e);
    }
  };

  const getCategoryAndAllChildren = async (params, concat) => {
    try {
      await dispatch(appActions.getCategoryAndAllChildren(params, concat));
    } catch (e) {
      console.warn(e);
    }
  };

  const getCategories = async () => {
    try {
      requestCategories = true;
      await dispatch(appActions.getCategories());
    } catch (e) {
      console.warn(e);
      requestCategories = false;
    }
  };

  return {
    requestCategories,
    getAllCategories,
    getCategoryAndAllChildren,
    getCategories,
  };
};
