import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { useMediaQuery } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { UserContext } from '../../providers/UserProvider';
import { profileActions } from '../../states/Profile';
import { getComponents } from './getComponent';
import messages from 'messages/Product';
import alertMessages from 'messages/AlertDialog';

import { useStyles } from './styles';
import { theme } from 'theme';

import haventImage from 'assets/images/placeholder.png';

const Index = ({
  intl,
  product,
  setProducts,
  saveChanges,
  isSelect,
  selected,
  isMyProfile,
  withoutSwitch,
  userId,
  newProductsTab,
  onUser,
  handleFavorite,
  isFavorite,
}) => {
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const alert = useAlert();
  const [open, setOpen] = useState('');
  const [editCard, setEditCard] = useState(false);
  const { user, openAuthForm } = useContext(UserContext);
  const [checked, setChecked] = useState(product?.state === 'active');
  const [select, setSelect] = useState(false);
  const isMobile = useMediaQuery('(max-width: 720px )');

  useEffect(() => {
    setChecked(product?.state === 'active');
  }, [product]);

  const onReview = product?.state !== 'active';

  const calculateDiscountPrice =
    product?.currency == 'KGS'
      ? Math.floor(
          (1 - product?.discount_price_kgs / product?.initial_price) * 100,
        )
      : Math.floor(
          (1 - product?.discount_price_usd / product?.initial_price) * 100,
        );

  const discountPrice =
    product?.discount >= 98 || product?.discount <= 2
      ? calculateDiscountPrice
      : product?.discount;

  const businessName =
    userId?.business_name?.length > 15
      ? product?.user?.business_name.slice(0, 15)
      : product?.user?.business_name;

  const region =
    product?.location?.region && product?.location?.region?.slice(0, 5) + '...';

  const handleDeleteClick = async (id) => {
    try {
      await dispatch(profileActions.deleteProduct(id));
      alert.success(intl.formatMessage({ ...messages.deletedProduct }));
      window.location.reload(false);
    } catch (e) {
      alert.error(intl.formatMessage({ ...alertMessages.errorRemove }));
    }
  };

  const handleAgree = (method) => {
    if (method === 'delete') {
      handleDeleteClick(product?.id);
    }
  };

  const checkHandler = () => {
    setChecked(!checked);
    saveChanges(product?.id, !checked ? 'active' : 'inactive');
  };

  const disableLink = (event) => {
    if (isSelect) {
      event.preventDefault();
    }
  };

  const selectHandler = () => {
    if (select) {
      selected(product, 'delete');
      setSelect(false);
    } else {
      selected(product, 'add');
      setSelect(true);
    }
  };

  const handleClick = () => setEditCard(!editCard);

  const handleCardClick = (e) => {
    if (e.target.type !== 'checkbox') {
      selectHandler();
    }
  };

  return (
    <div
      className={classes.productWrapper}
      onClick={isMyProfile && isSelect ? handleCardClick : null}
    >
      {getComponents('getProductState', true, {
        product,
        isFavorite,
        handleFavorite,
        disableLink,
        onReview,
        isSelect,
        isMyProfile,
        selectHandler,
        intl,
        messages,
        user,
        select,
        discountPrice,
        haventImage,
        openAuthForm,
      })}

      {getComponents('BusinessNameProduct', userId.is_business, {
        product,
        businessName,
      })}
      {getComponents('getLocation', true, {
        isMobile,
        isMyProfile,
        region,
        intl,
        selectHandler,
        handleClick,
        isSelect,
        withoutSwitch,
        onReview,
        product,
        select,
        onUser,
      })}

      {isMyProfile && !isSelect && !withoutSwitch && (
        <div
          className={classes.navigation}
          style={onReview ? { justifyContent: 'flex-end', marginTop: 0 } : null}
        ></div>
      )}
      {getComponents('getEditCard', !onReview || isMyProfile, {
        intl,
        product,
        messages,
        editCard,
        checkHandler,
        checked,
        handleAgree,
        open,
        setOpen,
        handleClick,
        isMyProfile,
        onReview,
        onUser,
      })}

      {getComponents('getEditCardNoReview', isMyProfile && onReview, {
        product,
        setProducts,
        editCard,
        open,
        setOpen,
        intl,
        handleAgree,
        messages,
        checked,
        checkHandler,
        handleClick,
        newProductsTab,
      })}
    </div>
  );
};

Index.propTypes = {
  intl: PropTypes.object,
  product: PropTypes.object,
  setProducts: PropTypes.func,
  saveChanges: PropTypes.func,
  isSelect: PropTypes.bool,
  selected: PropTypes.func,
  isMyProfile: PropTypes.bool,
  withoutSwitch: PropTypes.bool,
  userId: PropTypes.object,
  newProductsTab: PropTypes.string,
  onUser: PropTypes.bool,
};

export default React.memo(Index);
