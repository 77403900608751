import { Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../styles';
import DiscountPrice from './DiscountPrice';
import PriceProduct from './PriceProduct';
import { useAlert } from 'react-alert';

const PriceAndTitle = (props) => {
  const { intl, product, messages, isDiscount, onReview } = props;
  const classes = useStyles();
  const alert = useAlert();

  const maxTextLength = 30;

  const title =
    product?.title?.length > maxTextLength
      ? product?.title.slice(0, maxTextLength) + '...'
      : product?.title;

  return (
    <div
      className={classes.priceAndTitle}
      onClick={
        onReview
          ? () => {
              alert.error('Это объявление неактивное');
            }
          : undefined
      }
    >
      {isDiscount ? (
        <DiscountPrice
          intl={intl}
          product={product}
        />
      ) : (
        <PriceProduct
          intl={intl}
          product={product}
          messages={messages}
        />
      )}
      <Typography
        component={'p'}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {title}
      </Typography>
    </div>
  );
};

export default PriceAndTitle;
