import { requester } from 'services/requester';

export const getFavoriteIds = async () => {
  return await requester
    .get('/product/v1/personal/favorite/ids/')
    .then(({ data }) => {
      if (!data || !data.favorite_id) {
        console.warn('No favorite IDs returned from the response.');
        return [];
      }
      return data.favorite_id;
    })
    .catch((error) => {
      console.error('Failed to fetch favorite IDs:', error.message || error);
      throw new Error('Failed to fetch favorite IDs. Please try again later.');
    })
    .finally(() => {
      console.info('Finished fetching favorite IDs.');
    });
};

export const setFavoriteProduct = async (id) => {
  return await requester
    .post('/product/v1/personal/favorites/', { product: id })
    .then((response) => {
      if (response?.status !== 201) {
        console.warn(
          'No response data returned when setting favorite product.',
        );
        throw new Error('Failed to set favorite product. No data received.');
      }
      return response.status;
    })
    .catch((error) => {
      console.error('Failed to set favorite product:', error.message || error);
      throw new Error(
        'Failed to set favorite product. Please try again later.',
      );
    })
    .finally(() => {
      console.info('Finished setting favorite product.');
    });
};

export const deleteFavoriteProduct = async (id) => {
  return await requester
    .delete(`/product/v1/personal/favorites/${id}/`)
    .then((response) => {
      if (response.status !== 204) {
        console.warn(
          `No response data returned when deleting favorite product with ID: ${id}`,
        );
        throw new Error('Failed to delete favorite product. No data received.');
      }
      return response.status;
    })
    .catch((error) => {
      console.error(
        'Failed to delete favorite product:',
        error.message || error,
      );
      throw new Error(
        'Failed to delete favorite product. Please try again later.',
      );
    })
    .finally(() => {
      console.info(`Finished deleting favorite product with ID: ${id}`);
    });
};
