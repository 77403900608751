import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginTop: '15%',

    // boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1300,
    color: '#555555',
    '& a': {
      fontSize: 14,
    },
  },
  logo: {
    width: 100,
    height: 25,
    '@media (max-width:1075px)': {
      width: 95,
    },
  },
  buttonImage: {
    transition: 'all 0.3s',
    height: '35px',

    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  chatBudget: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: '#FC5C65',
    height: 12,
    width: 12,
    borderRadius: 12,
  },
  menu: {
    display: 'none',
    color: '#000000',
    minWidth: 30,
    '@media (max-width: 1040px)': {
      display: 'inline-flex',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 30,
    },
  },
  userMenuItem: {
    display: 'none',
    fontSize: 14,
    color: '#555',

    '@media (max-width: 1180px)': {
      display: 'flex',
    },
  },
  navLink: {
    marginRight: '20px',
    fontSize: 14,
    color: 'inherit',
    textDecoration: 'none',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 4,
    '@media (max-width: 1040px)': {
      display: 'none',
    },
  },
  activeMenuItem: {
    color: theme.palette.primary.blue,
    backgroundColor: theme.palette.primary.contrastText,
  },
  inMenu: {
    display: 'flex',
    margin: 0,
  },
  inUserMenu: {
    display: 'flex',
    padding: 0,
    '& .MuiIconButton-label': {
      color: '#000',
      fontSize: 14,
    },
    '& .MuiButtonBase-root': {
      padding: 0,
    },
    '& .MuiIconButton-root': {
      display: 'flex',
      height: 'auto',
    },
  },
  businessLink: {
    marginRight: '20px',
    fontSize: 14,
    color: 'inherit',
    textDecoration: 'none',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 4,
    // '@media (max-width: 1010px)': {
    //   display: 'none'
    // }
  },
  toolbar: {
    maxWidth: 960,
    width: '100%',
    margin: '0 auto',
    padding: '0 10px',
    '@media (max-width: 1130px)': {
      padding: '0 30px',
    },
    '@media (max-width: 920px)': {
      padding: '0 20px',
    },
  },
  postButton: {
    marginLeft: theme.spacing(2),
    borderRadius: '5px',
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'inherit',
    fontSize: 14,

    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  smallPostButton: {
    marginLeft: '10px',
    height: 35,
    width: 35,
    minWidth: 35,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.blue,
    padding: 5,
    marginRight: 10,

    '& svg': {
      fill: '#fff',
    },
  },
  grow: {
    flexGrow: 1,
  },
  authButton: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'inherit',
  },
  user: {
    width: 'fit-content',
    cursor: 'pointer',
    paddingLeft: 10,
    paddingRight: 10,
    '& div.user-name': {
      color: theme.palette.primary.blue,
      maxWidth: '50px',
      '& span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '@media (max-width: 1300px)': {
        display: 'none',
      },
    },

    '& svg::last-child': {
      color: theme.palette.primary.blue,
    },

    '& .MuiListItemAvatar-root': {
      minWidth: 40,
    },

    '& .MuiAvatar-root': {
      width: 36,
      height: 36,
    },

    '& .MuiListItemText-root': {
      color: theme.palette.primary.main,
    },
    '@media (max-width: 1050px)': {
      paddingLeft: 5,
      paddingRight: 0,
    },
  },
  disableHover: {
    '&:hover': {
      background: 'transparent',
    },
  },
  appButton: {
    padding: 0,
    margin: 0,
    background: 'transparent',
    width: '81px',

    '&:hover': {
      background: 'transparent',
    },

    '& img': {},
  },
  headerIcon: {
    padding: '7px 7px',
    borderRadius: '50%',
    height: '35px',
    '& path': {
      transition: 'all 0.4s',
    },
    '&:hover': {
      background: '#FAFAFA',
      '& path': {
        fill: '#0051BA',
      },
    },
    '@media (max-width: 1180px)': {
      display: 'none',
    },
  },
  headerIconForBabel: {
    borderRadius: '50%',
    height: '35px',
    '&:hover': {
      background: '#FAFAFA',
    },
  },
  svg: {
    cursor: 'pointer',
    '& path': {
      transition: 'all 0.4s',
    },
    '&:hover': {
      '& path': {
        fill: '#0051BA',
      },
    },
  },
  notificationAlert: {
    transition: 'all 0.2s',
    // position: 'absolute',
    // top: '65px',
    // left: '60%',
    width: '100%',
    height: '500px',
    borderRadius: '8px',
    padding: '10px',
    background: '#fff',
    boxShadow: '0px 2px 5px 0px #0000000D',
    display: 'block',
    opacity: '1',
    '@media (max-width: 1125px)': {
      left: '50%',
    },
    '@media (max-width: 1015px)': {
      left: '45%',
    },
    '@media (max-width: 920px)': {
      left: '50%',
    },
    '@media (max-width: 800px)': {
      left: '45%',
    },
  },
  title: {
    // width: '90%',
    // marginLeft: '5%',
    marginTop: '5%',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
    paddingBottom: '10px',
  },
  title1: {
    color: '#0051BA',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
  },
  close: {
    cursor: 'pointer',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    '&:hover': {
      background: '#FAFAFA',
    },
  },
  notifList: {
    marginTop: '10px',
    overflowY: 'scroll',
    height: '380px',
    boxShadow: '0 0 2px rgb(0 0 0 / 14%);',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#C4C4C4',
      borderRadius: '5px',
      border: '3px solid white',
    },
  },
  listItem: {
    width: '90%',
    marginLeft: '5%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
    marginTop: '15px',
    padding: '2px 7px',
    paddingBottom: '15px',
    transition: 'all 0.3s',
    cursor: 'pointer',
    '&:hover': {
      background: '#FAFAFA',
    },
  },
  titleNotify: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginLeft: '10px',
      fontSize: '12px',
      color: '#0051BA',
    },
    '& svg': {
      marginLeft: '5px',
      marginTop: '3px',
    },
  },
  description: {
    display: 'flex',
  },
  date: {
    fontSize: '12px',
    color: '#000000',
    opacity: '0.5',
    marginLeft: '5px',
  },
  titleProduct: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '250px',
    marginTop: '5px',
  },
  messageProduct: {
    fontSize: '12px',
    width: '250px',
  },
  moreInfo: {
    display: 'flex',
  },
  productImage: {
    width: '70px',
    height: '70px',
    marginLeft: '10px',
  },
  noNotifications: {
    marginTop: '120px',
    textAlign: 'center',
    '& img': {
      width: '40px',
    },
  },
  blackText: {
    color: '#3B414B',
    fontSize: '18px',
    fontWeight: '500',
  },
  greyText: {
    color: '#A6AAB4',
    fontSize: '14px',
    fontWeight: '500',
    width: '450px',
    // marginLeft: '108px',
    marginTop: '20px',
  },
  userMenu: {
    background: '#fff',
    minWidth: '140px',
    borderRadius: '4px',
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    marginTop: '7px',
    paddingTop: '5px',
    paddingBottom: '5px',
    '& a': {
      textDecoration: 'none',
      width: '100%',
      height: '100%',
      color: '#000000',
    },
  },
  settingsMenu: {
    background: '#fff',
    width: '234x',
    minHeight: '112px',
    borderRadius: '4px',
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    marginTop: '15px',
    paddingTop: '5px',
    paddingBottom: '5px',
    transform: 'scale(0.9)',
  },
  unvisableBlock: {
    width: '100%',
    height: '100vh',
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    zIndex: '99999 !important',
    display: 'none',
    opacity: '0',
  },
  divider: {
    height: 26,
    borderLeft: '1px solid #DFDFDF',
    marginRight: 6,
    marginLeft: 6,
    '@media (max-width: 1180px)': {
      display: 'none',
    },
  },
  lastDivider: {
    display: 'none',
    '@media (max-width: 1180px)': {
      display: 'inline-flex',
    },
  },
  notHidingDivider: {
    display: 'inline-flex',
  },
  select: {
    fontSize: 14,
    fontWeight: 400,

    '& .MuiButton-label': {
      height: 24,
    },
    '@media (max-width: 1040px)': {
      display: 'none',
    },
  },
  languageSelect: {
    marginLeft: 10,
  },
  settingsIcon: {
    display: 'inline-flex',
  },
  withoutUser: {
    marginRight: '20px',
    fontSize: 14,
    textDecoration: 'none',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 4,
  },
  withoutUserSupportService: {
    display: 'inline-flex',
  },
  anchorTopRight: {
    transform: 'translate(5%, -5%)',
  },
  exitModal: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  exitWindow: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    width: 250,
    minHeight: 210,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
  },
  exitWindowContent: {
    margin: '20px 10px 0',
    height: 180,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    '& p': {
      fontWeight: 'bold',
    },
  },
  exitBtn: {
    height: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  closeBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
    // bottom: 0,

    '& svg': {
      fill: 'rgb(118, 118, 118)',
      width: 20,
      height: 20,
    },
  },
  exit: {
    backgroundColor: '#FAC917',
    fontWeight: 'bold',
  },
  closeBtnNotification: {
    position: 'absolute',
    top: 0,
    right: 0,
    // bottom: 0,

    '& svg': {
      fill: '#000000',
      width: 30,
      height: 30,
    },
  },
  notifBlock: {
    display: 'none',
  },
  notifActive: {
    height: '500px',
    width: '450px',
    borderRadius: '8px',
    padding: '0',
    position: 'absolute',
    right: 20,
    display: 'block',
    zIndex: 99999,
    '&:hover': {
      backgroundColor: 'none',
    },
  },
  '@keyframes myEffect': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));
